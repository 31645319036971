import React from "react"
import Layout from "../../components/layout"
import Content from "../../components/particles/Content"
import styled from "@emotion/styled"
import Card from "../../components/molecules/Card/Card"
import Breadcrumbs from "../../components/organisms/breadcrumbs/Breadcrumbs"
import Banner from "../../components/organisms/banner/Banner"
import { graphql } from "gatsby"
import theme from "../../components/particles/Theme"
import EmergencyBanner from "../../components/organisms/emergencyBanner/EmergencyBanner"

const StyledProductCards = styled.div`
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;

  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
  }
`

const index = ({ data }) => {
  const marketImages = data.wp.productSearch.productSearchOptions.marketSegmentOptions

  return (
    <Layout title="Product Search">
      {/* <EmergencyBanner /> */}
      <Banner title="Product Search" />
      <Breadcrumbs />
      <Content>
        <StyledProductCards>
          <Card
            title="Residential"
            link="/products/residential/"
            backgroundImage={marketImages.residentialImage || undefined}
          />
          <Card
            title="Commercial"
            link="/products/commercial/"
            backgroundImage={marketImages.commercialImage || undefined}
          />
          <Card
            title="Specialty Commercial"
            link="/specialty-commercial/"
            backgroundImage={marketImages.specialtyCommercialImage || undefined}
          />
        </StyledProductCards>
      </Content>
    </Layout>
  )
}

export const query = graphql`query ProductSearchOptionsMarketSegment {
  wp {
    productSearch {
      productSearchOptions {
        marketSegmentOptions {
          commercialImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
          }
          residentialImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
          }
          specialtyCommercialImage {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            sourceUrl
          }
        }
      }
    }
  }
}
`

 export default index
